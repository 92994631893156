import { Config } from '../../config';
import SuperFetch from '../../helpers/superFetch';

export const updateProfile = async (data) => {
  try {
    const url = `${Config.WAPI_V2_URL}/nurses/profile`;
    const response = await SuperFetch.put(url, data);

    if (response) {
      return response;
    }
  } catch (error) {
    console.error('updateProfile', error);
  }
};

export const sendApplication = async (data, isUpsell = false, isSkip = false, source = 1) => {
  try {
    const url = `${Config.WAPI_V2_URL}/candidates/jobs/application${isUpsell ? '?isUpsell=1' + (isSkip ? '&fromSkip=1' : '') : ''}`;
    const response = await SuperFetch.post(url, { ...data, source });

    if (response) {
      return response;
    }
  } catch (error) {
    console.error('sendApplication', error);
  }
};

export const minimumInformationCheck = async (jobId) => {
  try {
    const url = `${Config.WAPI_V2_URL}/candidates/eligibility/${jobId}`;
    const result = await SuperFetch.get(url);

    return result.data;
  } catch(e) {
    // pass
  }
};

export const sendInterestedBatch = async (jobIds, chat, sendChatContact = false) => {
  try {
    const url = `${Config.WAPI_V2_URL}/candidate/interested`;
    const interestedRes = await SuperFetch.post(url, {
      job_ids: jobIds,
      chat,
      sendChatContact
    });

    return interestedRes.data;
  } catch(e) {
    console.error(e);
  }
}

export const getInterestedJobs = async () => {
  try {
    const url = `${Config.WAPI_URL}/nurses/interested`;
    const response = await SuperFetch.get(url)
    return response.data;
  } catch (error) {
    console.log(error)
  }
};
