import { Config } from '../config';
import SuperFetch from '../helpers/superFetch';
import {useSelector} from "react-redux";
import {NurseSelector} from "../redux/slices/nurse";

const UseInteractions = () => {
  const { auth: { data: nurseData } } = useSelector(NurseSelector);

  const submitInteraction = async (formData) => {
    try {
      const data = await SuperFetch.post(`${Config.WAPI_V2_URL}/interactions`, {
        ...formData
      });

      if (data.success) {
        return data.data;
      }

      return false;
    } catch (e) {
      console.error(e);
    }
  };

  return [submitInteraction];
};

export default UseInteractions;
