import React, {useEffect, useState, useLayoutEffect} from "react";

import { ReactComponent as LoadingSpinner } from '../../assets/images/loading/spinner.svg';
import CityCard from "../../components/CityCard";
import css from './TravelNursingCities.module.scss';
import {addRelativeFooter} from "../../helpers/dom";
import NavigationMenu from "../../components/HeroSection/NavigationMenu";
import {useDispatch, useSelector} from "react-redux";
import {getUserData, NurseSelector} from "../../redux/slices/nurse";
import {getNotifications} from "../../redux/slices/notifications";

const TravelNursingCities = () => {
  const [isLoading, setIsLoading] = useState(false);
  const cities = window.cities || [];
  const filterCities = cities.filter((city) => +city.total_jobs >= 10);
  const countCities = filterCities.length > 20 ? 20 : filterCities.length;
  const [viewMore, setViewMore] = useState(false);
  const { auth: { data } } = useSelector(NurseSelector);
  const dispatch = useDispatch();
  const [user, setUser] = useState('');
  const matchMobile = window.matchMedia('(max-width: 992px)');
  const isMobile = matchMobile.matches;

  // HOOKS
  useEffect(() => {
    dispatch(getNotifications());
    dispatch(getUserData());
  }, []);

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (isMobile) {
      addRelativeFooter();
    }
  }, [isMobile]);

  const toggleResults = ( ) => {
    setViewMore(!viewMore)
  }

  const renderCities = () => {
    const citiesRender = viewMore ? filterCities.slice(0, filterCities.length) : filterCities.slice(0, countCities);

    return citiesRender.map((city) => {
      return (<div key={city.id}>
        <CityCard city={city} />
      </div>)
    })
  }

  return (<>
    <NavigationMenu isActive={'travel-nursing-destinations'}/>
    <section className={`padding-sticky-bar full-width ${css['travel-nursing-cities']}`}>
      <div className={`container-custom flex flex-column ${css['travel-nursing-cities-header']}`}>
        <div className="title">
          <h1>Find Travel Nursing Jobs by City</h1>
        </div>
        <div className="subtitle">
          <h2>CHOOSE YOUR DESTINATION</h2>
        </div>
      </div>
      <div className={`full-width py-4 py-sm-5 ${css['travel-nursing-cities-body']}`}>
        <div className="container-custom flex flex-column">
          <div className="info">
            <h1>Our top travel contract cities</h1>
            <p>
              Looking for that exciting travel nursing job you've been dying to take? We've got you covered.
              Browse and apply for the industry's most sought-after travel nursing assignments in destinations from Honolulu to New York.
            </p>
          </div>
          { isLoading
            ? <div className={`loading-spinner flex py-5 justify-content-center`}> <LoadingSpinner alt="oval" /> </div>
            : (<>
              <div className="cities-grid">
                { renderCities() }
              </div>
              {filterCities.length > 20 && (<div className={`flex py-5 justify-content-center`}>
                <button className={css['view-more']} onClick={() => toggleResults()}> View {viewMore ? 'Less' : 'More'}</button>
              </div>)}
            </>)
          }
        </div>
      </div>
    </section>
  </>)
}

export default TravelNursingCities;
