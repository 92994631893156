let Config = {};

if (process.env.NODE_ENV === 'development') {
  Config = {
    WAPI_URL: process.env.REACT_APP_WAPI_URL ?? 'http://127.0.0.1:81/api/v1',
    NORTHSIDE_CHOICE_ID: process.env.REACT_APP_NORTHSIDE_CHOICE_ID ?? 0,
    WAPI_V2_URL: process.env.REACT_APP_WAPI_V2_URL ?? 'http://wapi.wanderly.lc/api/v2',
    WAP_API_URL: process.env.REACT_APP_WAP_API_URL ?? 'http://localhost/api/v1',
  };
} else {
  Config = {
    WAPI_URL:
      process.env.REACT_APP_WAPI_URL ??
      'http://ec2-54-214-170-146.us-west-2.compute.amazonaws.com/api/v1',
    NORTHSIDE_CHOICE_ID: process.env.REACT_APP_NORTHSIDE_CHOICE_ID ?? 0,
    WAPI_V2_URL:
      process.env.REACT_APP_WAPI_V2_URL ||
      'http://ec2-54-214-170-146.us-west-2.compute.amazonaws.com/api/v2',
    WAP_API_URL: process.env.REACT_APP_WAP_API_URL ?? 'https://www.wanderly.us/api/v1',
  };
}

export { Config };