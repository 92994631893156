import React from "react";
import {useMediaQuery} from "react-responsive";

import { ReactComponent as AppStore } from '../../assets/images/pages/home/app_store.svg';
import { ReactComponent as GooglePlayStore } from '../../assets/images/pages/home/google_play.svg';
import QRLocal from '../../assets/images/pages/home/QR_local.png';
import QRDev from '../../assets/images/pages/home/QR_dev.png';
import QRStage from '../../assets/images/pages/home/QR_staging.png';
import QRProd from '../../assets/images/pages/home/QR_production.png';

import './StatCounter.style.scss';

const CandidateAppManager = ({ showTitle = true, isModal = false }) => {
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const getMobileOperatingSystem = () =>  {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }

    return null;
  }

  const getQR = () => {
    const config = {
      local: QRLocal,
      dev: QRDev,
      staging: QRStage,
      production: QRProd
    }

    return config[window.env] || config.local
  }

  return (
      <>
        <div className={`wanderly-title ${isModal ? 'text-center' : ''}`}>
          {showTitle && (<h2 className="label-title font-weight-bold">Download Wanderly App</h2>)}
          <p className={`candidate-manager-p ${isModal ? 'text-center' : ''}`}>
            Compare Travel, Permanent, Per-Diem Nursing and Allied jobs from leading healthcare agencies
          </p>
        </div>
        <div className={`logos-qr-container ${!showTitle ? 'logos-qr-container-modal' : ''}`}>
          <div className="logos-container">
            {isLargeScreen ? (
                <>
                  <a
                      href="https://apps.apple.com/us/app/wanderly-candidate-app/id6446382560"
                      target="_blank"
                      title="Wanderly Mobile App - Apple / iPhone"
                  >
                    <AppStore className="app-logos" />
                  </a>
                  <a
                      href="https://play.google.com/store/apps/details?id=us.wanderly.candidate"
                      target="_blank"
                      title="Wanderly Mobile App - Android / Google"
                  >
                    <GooglePlayStore className="playstore_logo app-logos" />
                  </a>
                </>
            ) : (
                <>
                  {getMobileOperatingSystem() === 'ios' && (
                      <a
                          href="https://apps.apple.com/us/app/wanderly-candidate-app/id6446382560"
                          target="_blank"
                          title="Wanderly Mobile App - Apple / iPhone"
                      >
                        <AppStore className="app-logos" />
                      </a>
                  )}
                  {getMobileOperatingSystem() === 'android' && (
                      <a
                          href="https://play.google.com/store/apps/details?id=us.wanderly.candidate"
                          target="_blank"
                          title="Wanderly Mobile App - Android / Google"
                      >
                        <GooglePlayStore className="playstore_logo app-logos" />
                      </a>
                  )}
                </>
            )}
          </div>
          <div className="qr-container">
            <p>Scan QR code to download the app</p>
            <img
                src={getQR()}
                alt="Download app candidate"
                className="qr-img"
            />
          </div>
        </div>
      </>
  )
}

export default CandidateAppManager;
