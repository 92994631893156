import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {JobsSelector} from "../../redux/slices/jobs";
import { professionsSelector } from "../../redux/slices/professions";
import { ReactComponent as BCIcon } from '../../assets/images/icons/breadcrumbs_arrow_icon.svg';
import { ReactComponent as LocalContractIcon } from '../../assets/images/icons/localContract.svg';


import {isEmpty}  from 'underscore';
import './BreadC.style.scss';
import { getProfession } from "../../helpers/utils";


const BreadC = ({ containerClass = '', clickable = false}) => {
  const { filters: { profession_id, typeJob, specialty_ids, location } = {} } = useSelector(JobsSelector);

  const { professions } = useSelector(professionsSelector);

  const [jobLabel, setJobLabel] = useState('');
  const [professionLabel, setProfLabel] = useState('');
  const [specialtyLabel, setSpecLabel] = useState('');
  const [locationLabel, setLocationLabel] = useState('');

  useEffect(() => {
    const newlabel = getJobLabel(typeJob);
    if(jobLabel !== newlabel){
      setJobLabel(newlabel);
    }
  }, [typeJob]);

  useEffect(() => {
    let newlabel = '';
    if(profession_id){
       newlabel = !isNaN(profession_id) ? getProfession(profession_id) : profession_id.label;
    }
    if(professionLabel !== newlabel){
      setProfLabel(newlabel);
    }
  }, [profession_id]);

  useEffect(() => {
    let newlabel = '';
    if(!isEmpty(specialty_ids)){
      newlabel = specialty_ids.length > 1 ? '' : specialty_ids[0].label;
    }
    if(specialtyLabel !== newlabel){
      setSpecLabel(newlabel);
    }
  }, [specialty_ids]);


  useEffect(() => {
    let newlabel = '';
    if(!isEmpty(location)){
      newlabel = location.length > 1 ? '' : location[0].label;
    }
    if(locationLabel !== newlabel){
      setLocationLabel(newlabel);
    }
  }, [location]);


  const getJobLabel = (_typeJob) => {
    return JOB_TYPES[_typeJob];
  }
  
  const JOB_TYPES = {
    domestic: 'Travel',
    permanent: 'Permanent',
    local: 'Local',
    irp: 'IRP',
    perDiem: 'Per Diem',
  };

  const handleClick = (type) => {
    if(clickable){
      window.location = getLinkUrl(type);
    }
  }

  const getLinkUrl = (type)=> {
    let _url = window.location.origin;
    let _jobLabel = jobLabel?.toLowerCase();
    let _professionLabel = professionLabel?.toLowerCase();
    let _specialtyLabel = specialtyLabel?.toLowerCase();
    switch (type) {
      case 'search':
        _url += '/nurse/jobs/search'
        break;
      case 'jobs':
        if(specialtyLabel || locationLabel){
          _url += `/jobs/${_jobLabel}/${_professionLabel}`
        }
        break;
      case 'specialty':
        if(locationLabel){
          _url += `/jobs/${_jobLabel}/${_professionLabel}/${_specialtyLabel}`
        }
        break;
      default:
    }
    return _url.replace(' ', '-');
  }
  const getBreadCrumbsSchema = () =>  {

    let itemListElement = [];
    itemListElement.push(
      {
        "@type": "ListItem",
        "position": 1,
        "item":
        {
         "@id": getLinkUrl(),
         "name": "Home"
         }
      }
    );

    itemListElement.push(
      {
        "@type": "ListItem",
        "position": 2,
        "item":
        {
         "@id": getLinkUrl('search'),
         "name": "Jobs"
         }
      }
    );

    if(specialtyLabel || locationLabel){
      itemListElement.push(
        {
          "@type": "ListItem",
          "position": 3,
          "item":
          {
           "@id": getLinkUrl('jobs'),
           "name": jobLabel + ' '+ professionLabel
           }
        }
      );
    }

    if(specialtyLabel && locationLabel){
      itemListElement.push(
        {
          "@type": "ListItem",
          "position": 4,
          "item":
          {
           "@id": getLinkUrl('specialty'),
           "name": specialtyLabel
          }
        }
      );
    }


    const schema  = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement
    }
    return JSON.stringify(schema);
  }
  return (
    <>
      <script
        id="schema-json-breadCrumbs"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: getBreadCrumbsSchema()
        }}
      />
      <div className={` ${containerClass} `}>
        <div className={`bread-container ${clickable && 'clickable'}`}>
          <div className={`b-home`} onClick={() => { handleClick('home') }}>
            Home
          </div>
          <BCIcon className={`b-icon`}/>
          <div className={`b-jobs `} onClick={() => { handleClick('search') }}>
            Jobs      
          </div>
          <BCIcon className={`b-icon`}/>
          <div className={`b-jobtype `} onClick={() => { handleClick('jobs') }}>

            {jobLabel}
            {' '}
            {professionLabel}
          </div>
          { specialtyLabel &&
            <>
              <BCIcon className={`b-icon`}/>
              <div className={`b-specialty `} onClick={() => { handleClick('specialty') }}>
                {specialtyLabel}
              </div>
            </>
          }
          { locationLabel &&
            <>
              <BCIcon className={`b-icon`}/>
              <div className={`b-location`}>
                {locationLabel}
              </div>
            </>
          }
        </div>
      </div>
    </>
  )
}

export default BreadC;