import React, { useState } from 'react';
import { ReactComponent as LoadingSpinner } from '../../assets/images/loading/spinner.svg';
import noResultsImg from '../../assets/images/pages/search/sad-wanda.png';
import JobCard from '../JobCard';
import MobileCards from './MobileCards';
import DesktopCards from './DesktopCards';
import BreadC from '../BreadC';
import { formatNumberWithCommas } from '../../utils/textFormat';

// prettier-ignore
const JobsCardContainer = ({
  isLoading,
  jobs,
  total,
  totalPP,
  currentPage,
  limit,
  interestedJobs,
  filters,
  showResultItemsClass,
  isMobile,
  scrollFetchData,
  errors,
  payPackages,
  toggleToCriteria,
  showingMobileSeoCity,
  toggleInterestedModal,
  toggleSimilarJobsModal,
  showingFaq
}) => {
  const triggerModal = (e, job) => {
    e.stopPropagation();
  }

  const jobCards = jobs.map((job) => {
    return <JobCard
      key={job.id}
      job={job}
      interestedJobs={interestedJobs}
      filters={filters}
      page={currentPage}
      onInterestedAction={triggerModal}
      toggleInterestedModal={toggleInterestedModal}
      toggleSimilarJobsModal={toggleSimilarJobsModal}
    />;
  });

  return (<div className={`job-cards-container ${showResultItemsClass}`}>
    {
      isLoading
        ? <div className={`loading-spinner ${showingMobileSeoCity ? 'loading-top' : ''}`}> <LoadingSpinner /> </div>
        : <>
          {
            (errors || (total === 0 && filters.typeJob === 'permanent')) ?
              <div className="no-result-container">
                <div className="text-center"><img src={noResultsImg} className="img-responsive" /></div>
                <div className="text-center mt-3 mb-2 message"> Sorry, no results were found.</div>
                {
                  isMobile
                    ?  <button type="button" className="btn-link text-center my-2 try-again" onClick={toggleToCriteria}> Try widening your criteria </button>
                    :  <div className="text-center my-2 try-again"> Try widening your criteria </div>
                }
              </div>
            : total === 0
              ? (
                <>
                  <BreadC containerClass={'only-mobile'} clickable={true}/>
                  <div className="no-result-container">
                    <div className="text-center"><img src={noResultsImg} className="img-responsive" /></div>
                    <div className="text-center mt-3 mb-2 message"> Sorry, no results were found.</div>
                    {
                      isMobile
                          ?  <button type="button" className="btn-link text-center my-2 try-again" onClick={toggleToCriteria}> Try widening your criteria </button>
                          :  <div className="text-center my-2 try-again"> Try widening your criteria </div>
                    }
                  </div>
                </>
              )
              : <>
                <div className={isMobile ? 'flex-container mobile-breadcrumb' : ''}>
                  <BreadC containerClass={'only-mobile'} clickable={true}/>
                  <div className="number-total-packages">{formatNumberWithCommas(payPackages)} of {formatNumberWithCommas(totalPP)} jobs</div>
                </div>
                {
                  isMobile && !showingFaq
                    ? <MobileCards jobCards={jobCards} jobs={jobs} scrollFetchData={scrollFetchData} currentPage={currentPage} total={total} />
                    : <DesktopCards jobCards={jobCards} currentPage={currentPage} total={total} limit={limit} filters={filters} />
                }              
              </>
          }
        </>
    }
  </div>)
};

export default JobsCardContainer;
