import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { NurseSelector } from '../redux/slices/nurse';
import {getProfession} from "../helpers/utils";

const useBasicApplicationForm = (
  unregister,
  getValues,
  setValue,
  formValues,
  setFormValues,
  trigger,
  job = null,
  setError,
  clearErrors
) => {
  const { accessToken } = useSelector(NurseSelector);
  const [validationError, setValidationError] = useState(null);

  const parseExperienceSpecialties = (dataForm, profileData) => {
    // Prepare data before submit
    let specialties = [];
    let experience = 0;

    const filterSpecialties = dataForm.specialties;
    const filterSpecialtiesYears = dataForm.specialties_experience;

    for (const key in filterSpecialties) {
      if (filterSpecialties[key]) {
        for (const key2 in filterSpecialtiesYears) {
          if (filterSpecialtiesYears[key2]) {
            experience = +filterSpecialtiesYears[key];
          }
        }

        specialties.push({
          id: filterSpecialties[key].value,
          name: filterSpecialties[key].label,
          experience,
        });
      }
    }

    // Merge Job Specialties with profileData Specialties
    if (profileData?.specialties && profileData?.specialties.length > 0) {
      const profileSpecialties = profileData.specialties.map((s) => ({
        experience: s.years_exp,
        id: s.speciality_id,
        name: s.speciality.name,
      }));

      const mergedSpecialties = [...specialties, ...profileSpecialties];
      specialties = Array.from(new Set(mergedSpecialties.map((a) => a.id))).map((id) =>
        mergedSpecialties.find((a) => a.id === id)
      );
    }

    return specialties;
  };

  const isDisabled = (field, profile) =>
    (field === 'email' && profile?.email_verified) ||
    (field === 'phone' && profile?.phone_verified);

  const isValidJobRequirements = (formData) => {
    if (job.profession_id !== formData.profession && job.profession_id !== formData.profession_id) {
      setError('profession', {
        type: null,
        message: `This job requires ${getProfession(job.profession_id)} Profession`,
      });

      return false;
    }

    if (job?.specialty_ids?.length) {
      let specialtyName = '';

      const requiredSpecialties = job?.specialty_ids?.filter((jobSpecialty, index) => {
        specialtyName = job.specialty[index];

        return !formData.specialties.some(
          (userSpecialty) =>
            userSpecialty.id === jobSpecialty || userSpecialty.speciality_id === jobSpecialty
        );
      });


      if (
        (job?.specialty_ids?.length === 1 && requiredSpecialties?.length) ||
        (job?.specialty_ids?.length > 1 && requiredSpecialties?.length > 1)
      ) {
        const lastSpecialty = formData.specialties.at(-1);

        setError(`specialties[${lastSpecialty.speciality_id}]`, {
          type: 'specialties',
          message: `This job requires ${specialtyName} Specialty`,
        });

        return false
      }
    }

    return true;
  };

  const asyncValidation = () => {
    const currentFormValues = getValues();

    if (job.profession_id !== currentFormValues.profession) {
      setError('profession', {
        type: null,
        message: `This job requires ${getProfession(job.profession_id)} Profession`,
      });

      return false;
    }

    let specialtyName = '';
    let specialtyId = 0;

    if (currentFormValues.specialties) {
      const { specialties } = currentFormValues;

      const requiredSpecialties = job.specialty_ids.filter((jobSpecialty, index) => {
        specialtyName = job.specialty[index];

        return !specialties.some((userSpecialty, index) => {
          specialtyId = index;
          return userSpecialty?.value === jobSpecialty;
        });
      });

      if (
        (job.specialty_ids.length === 1 && requiredSpecialties?.length) ||
        (job.specialty_ids.length > 1 && requiredSpecialties?.length > 1)
      ) {
        setError(`specialties[${specialtyId}]`, {
          type: 'specialties',
          message: `This job requires ${specialtyName} Specialty`,
        });

        return false;
      }
    }

    clearErrors('profession');
    clearErrors('specialties');
  };


  const removeSpecialty = (specialtyToDelete) => {
    const oldSpecialties = formValues.specialties;

    const newSpecialties = oldSpecialties.filter(
      (specialty) => specialtyToDelete !== specialty.value
    );

    unregister(`specialties[${specialtyToDelete}]`);
    unregister(`specialties_experience[${specialtyToDelete}]`);

    newSpecialties.forEach((s) => {
      const dataDropdown = getValues(`specialties[${s.value}]`);
      const experience = getValues(`specialties_experience[${s.value}]`);

      setValue(`specialties[${s.value}]`, dataDropdown);
      setValue(`specialties_experience[${s.value}]`, experience);

      return s;
    });

    setFormValues({
      specialties: newSpecialties,
    });

    asyncValidation();
  };

  const getErrors = (errors, name, type) => {
    if (!errors?.[type]) {
      return null;
    }

    let index = false;
    let errorObj;

    if (name.includes('[')) {
      index = name.split('[')[1];
      index = index.substring(0, index.length - 1);

      errorObj = errors?.[type]?.[index];
    } else {
      errorObj = Object.values(errors?.[type]).find(
        (error) => error?.ref?.name === name || error?.message
      );
    }

    if (!errorObj) return false;

    if (errorObj?.message) {
      return errorObj;
    }

    return {
      type: 'required',
      message: name.includes('specialties_experience')
        ? 'Enter years experience'
        : 'This field is required',
    };
  };

  const matchSpecialtyProfileWithJob = (profile, job) => {
    const matchSpecialties = profile.specialties.filter((userSpecialty) =>
      job?.specialty_ids?.includes(userSpecialty.speciality_id)
    );

    if (!matchSpecialties.length) {
      const matchSpecialty = profile?.specialties.at(-1);

      return [
        {
          value: matchSpecialty.speciality.id,
          label: matchSpecialty.speciality.name,
          fakeLabel: matchSpecialty.speciality.name,
          disallowToDelete: true,
          years_exp: matchSpecialty.years_exp,
          showCross: true,
          ...matchSpecialty.speciality,
        },
      ];
    }

    return matchSpecialties.map((specialty) => ({
      value: specialty.speciality.id,
      label: specialty.speciality.name,
      fakeLabel: specialty.speciality.name,
      disallowToDelete: true,
      years_exp: specialty.years_exp,
      showCross: true,
      ...specialty.speciality,
    }));
  };

  const onSelectOption = async (value, select, name) => {
    if (name === 'profession') {
      setValue('specialties', undefined);
      const specialtyWithData = {
        value: 0,
        label: null,
        fakeLabel: null,
        disallowToDelete: true,
        showCross: true,
      };

      setValue(`specialties[0]`, null);
      setValue(`specialties_experience[0]`, null);

      setFormValues({
        selectedProfession: value,
        specialties: [specialtyWithData],
      });
    }

    setValue(name, value);
    await trigger();

    asyncValidation();
  };

  const addSpecialtyToList = async () => {
    const oldSpecialties = formValues.specialties;
    const newSpecialtyId = oldSpecialties.length + (+(Date.now().toString().slice(-5)));

    setValue(`specialties[${newSpecialtyId}]`, null);
    setValue(`specialties_experience[${newSpecialtyId}]`, null);

    const newSpecialties = [
      ...oldSpecialties,
      {
        value: newSpecialtyId,
        isClearable: true,
        name: `specialties[${newSpecialtyId}]`,
        valueSelected: null,
      },
    ];

    setFormValues({
      specialties: newSpecialties,
    });

    await trigger(`specialties[${newSpecialtyId}]`);
    await trigger(`specialties_experience[${newSpecialtyId}]`);
  };

  return {
    parseExperienceSpecialties,
    isDisabled,
    isValidJobRequirements,
    removeSpecialty,
    getErrors,
    matchSpecialtyProfileWithJob,
    onSelectOption,
    addSpecialtyToList,
    validationError,
    setValidationError,
    asyncValidation,
  };
};

export default useBasicApplicationForm;
