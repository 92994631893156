const CHANNEL_INTERACTION = {
  I_AM_INTERESTED: 'I_AM_INTERESTED',
  QUICK_APPLY: 'QUICK_APPLY',
  SEND_DOCUMENTS_INBOX: 'SEND_DOCUMENTS_INBOX',
  JOB_BOARD_LANDING_PAGE: 'JOB_BOARD_LANDING_PAGE',
  CHAT_NOW: 'CHAT_NOW',
};

const TRIGGER_INTERACTION = {
  GENERAL: 'GENERAL',
  SIMILAR_JOBS: 'SIMILAR_JOBS',
  HOT_LEADS: 'HOT_LEADS',
};

export {
  CHANNEL_INTERACTION,
  TRIGGER_INTERACTION,
};
