import { useEffect, useRef, useState } from 'react';
import SuperFetch from "../helpers/superFetch";
import {Config} from "../config";
import {useSelector} from "react-redux";
import {NurseSelector} from "../redux/slices/nurse";

const UseResume = (setValue, trigger) => {
  const [file, setFile] = useState(null);
  const [hasResume, setHasResume] = useState(false);
  const [errorUploadMessage, setErrorUploadMessage] = useState('');
  const [isLoadingApplication, setIsLoadingApplication] = useState(true);
  const inputFileRef = useRef(null);
  const { auth: { data: nurseData } } = useSelector(NurseSelector);

  const updateDocuments = async (formData) => {
    try {
      const response = await SuperFetch.post(
        `${Config.WAP_API_URL}/nurses/documents/resume/`,
        formData,
        true
      );

      if (response.success) {
        return response;
      }

      console.error('Error updating documents');
    } catch (e) {
      console.error(e);
    }
  };

  const getResumes = async () => {
    const { success, data } = await SuperFetch.get(`${Config.WAPI_V2_URL}/nurses/documents/resume/`);

    if (success) {
      setHasResume(true);
      const fileUploaded = data.at(-1);

      const file = {
        name: fileUploaded.name,
        lastModified: new Date(fileUploaded.created_at),
      }

      if (file) {
        setFile(file);
        setValue('resume', file);
        await trigger('resume');
      }
    }

    setIsLoadingApplication(false);
  };

  useEffect(async () => {
    setIsLoadingApplication(false);

    if (nurseData?.profile) {
      if (nurseData?.profile?.has_resume) {
        setIsLoadingApplication(true);

        await getResumes();
      } else {
        setIsLoadingApplication(false);
      }
    }
  }, [nurseData]);

  const handleFileChange = async (e) => {
    setErrorUploadMessage(null);

    const selectedFile = e.target.files[0];

    // Check if a file is selected
    if (selectedFile) {
      // Check file type
      const allowedTypes = ['.pdf', '.jpeg', '.jpg', '.png', '.bmp', '.doc', '.docx'];
      const fileType = selectedFile.name.slice(
        ((selectedFile.name.lastIndexOf('.') - 1) >>> 0) + 2
      );

      if (!allowedTypes.includes(`.${fileType.toLowerCase()}`)) {
        setErrorUploadMessage('Invalid file type. Please select a valid file type.');

        return;
      }

      // Check file size (optional)
      if (selectedFile.size > 20000000) {
        setErrorUploadMessage(
          'File size exceeds the maximum allowed limit (5 MB). Please select a smaller file.'
        );

        return;
      }

      setHasResume(false);
      setFile(selectedFile);
      setValue('resume', selectedFile);
      await trigger('resume');
    }
  };

  const clearData = async () => {
    setFile(null);
    inputFileRef.current.click();
    setValue('resume', null);
    await trigger('resume');
  };

  const submitResumeFile = async (resumeData = null, defaultResume = false) => {
    let resumeFile;
    let resumeName;

    if (resumeData) {
      resumeFile = await fetch(resumeData.url).then((r) => r.blob());
      resumeName = resumeData.filename;
    } else {
      resumeFile = file;
      resumeName = file.name;
    }

    const formData = new FormData();
    formData.append('type', 'RESUME');
    formData.append('filename', resumeFile);
    formData.append('filename_back', resumeFile);
    formData.append('name', resumeName);
    formData.append('default_resume_id', defaultResume ? '1' : '0');

    return await updateDocuments(formData);
  };

  return [
    file,
    handleFileChange,
    errorUploadMessage,
    isLoadingApplication,
    inputFileRef,
    clearData,
    submitResumeFile,
    hasResume
  ];
};

export default UseResume;
