const baseConfig = {
  first_name: {
    key: 'first_name',
    name: 'first_name',
    colClass: 'col-6 col-sm-6',
    type: 'input',
    label: 'First Name',
    placeHolder: 'Enter your name',
    rules: {
      required: 'Enter first name',
    },
    typeText: 'text',
  },
  last_name: {
    key: 'last_name',
    name: 'last_name',
    colClass: 'col-6 col-sm-6',
    type: 'input',
    label: 'Last Name',
    placeHolder: 'Enter your name',
    rules: {
      required: 'Enter last name',
    },
    typeText: 'text',
  },
  phone: {
    key: 'phone',
    name: 'phone',
    colClass: 'col-12 col-sm-6',
    type: 'maskedInput',
    label: 'Phone Number',
    mask: 'phone',
    maskPlaceHolder: '(555)-555-5555',
    required: true,
    rules: {
      required: 'Enter phone number',
      minLength: {
        value: 12,
        message: 'Please specify a valid phone number.',
      },
      maxLength: {
        value: 12,
        message: 'Please specify a valid phone number.',
      },
    },
  },
  email: {
    key: 'email',
    name: 'email',
    colClass: 'col-12 col-sm-6',
    type: 'input',
    label: 'Email',
    placeHolder: 'Enter your email',
    required: true,
    rules: {
      required: true,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: 'Please specify a valid email.',
      },
    },
    typeText: 'email',
  },
  legal_worker: {
    key: 'legal_worker',
    name: 'legal_worker',
    colClass: 'col-12 mt-3',
    type: 'checkbox',
    label: 'I am legally authorized work in the United States',
    placeHolder: '',
    required: true,
    rules: {
      required: 'this field is required',
    },
    typeText: 'checkbox',
    isOptional: false,
  },
  profession: {
    key: 'profession',
    colClass: 'col-8 col-sm-6',
    type: 'profession',
    label: 'Profession',
    required: true,
    rules: {
      validate: (value) => typeof value !== 'undefined',
      required: 'Select profession',
    },
  },
  years_experience_profession: {
    key: 'years_experience_profession',
    name: 'years_experience_profession',
    colClass: 'col-4 col-sm-6',
    cssClass: 'years-exp',
    type: 'input',
    label: 'Years Experience',
    placeHolder: '',
    required: true,
    rules: {
      required: 'Enter years experience',
      min: 1,
    },
    typeText: 'text',
  },
};

const ApplyFormConfig = {
  ...baseConfig,
};
//
// Custom Configuration per Landing page
export default ApplyFormConfig;
