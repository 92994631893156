import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@orionhcs/wanda-js-components';
import { MobileNav } from './MobileNav';
import { getSimilarJobs } from '../../redux/slices/job';
import { useDispatch, useSelector } from 'react-redux';
import SimilarJobList from '../SimilarJobList';
import {
  JobSelector
} from '../../redux/slices/job';
import { Oval } from 'svg-loaders-react';
import css from './similarJobsModal.module.scss';
import { ReactComponent as FlightIcon } from '../../assets/images/icons/icon-flight.svg';
import { ReactComponent as Warning } from '../../assets/images/icons/roundWarning.svg';
import { myMD5 } from '../../helpers/utils';
import NoSimilarJobs from '../NoSimilarJobs';
import UseInteractions from "../../hooks/useInteractions";
import {CHANNEL_INTERACTION, TRIGGER_INTERACTION} from "../../helpers/constants/interactions";

const SimilarJobsModal = ({ toggle, job}) => {
  const dispatch = useDispatch();
  const { similarJobs: { data, isLoading } } = useSelector(JobSelector);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isOnSubmitLoading, setIsOnSubmitLoading] = useState(false);
  const [isOnSkipLoading, setIsOnSkipLoading] = useState(false);
  const similarJobListRef = useRef();
  const [submitInteraction] = UseInteractions();

  const _$ = window.jQuery;
  const jobId = job?.job_id;

  useEffect(() => {
    dispatch(getSimilarJobs(jobId));
  }, []);

  const handleJobApplication = async () => {
    if (job.apply_now_redirect_url) {
      if (_$('#candidateRedirectionModal .agency-name').length) {
        _$('#candidateRedirectionModal .agency-name').text(job.agency_name);
      }
      if (_$('#redirection-continue-btn').length) {
        _$('#redirection-continue-btn')
          .data('redirectionType', 'apply')
          .data('jobId', job.job_id);
      }
      _$('#candidateRedirectionModal').modal('show');
      _$('.modal-backdrop').addClass('authModal');
    } else {
      _$(document).trigger('ShowSuccessCheckmarkEvt', 2500);
      _$.cookie('questionarymodalshown', myMD5(window.loggedInUser), {
        path: '/',
      });
    }
  }

  const onSubmit = async () => {
    setIsOnSubmitLoading(true);
    const checkedJobs = similarJobListRef.current.getCheckedJobs();
    const hotLeadJobs = similarJobListRef.current.getUnCheckedJobs();

    // Submit Hotlead
    const responseInteraction = await submitInteraction({
        agency_id: job.agency_id[0],
        job_id: +jobId,
        hot_lead_job_ids: hotLeadJobs,
        similar_job_ids: [],
        channel: CHANNEL_INTERACTION.I_AM_INTERESTED,
        trigger: TRIGGER_INTERACTION.HOT_LEADS,
      });

    if (checkedJobs.length > 0) {
      // Submit Similar job
      await submitInteraction({
        agency_id: job.agency_id[0],
        job_id: +jobId,
        hot_lead_job_ids: [],
        similar_job_ids: checkedJobs,
        channel: CHANNEL_INTERACTION.I_AM_INTERESTED,
        trigger: TRIGGER_INTERACTION.SIMILAR_JOBS,
      });
    }

    setIsOnSubmitLoading(false);

    if (responseInteraction) {
      toggle();

      await handleJobApplication();
    } else {
      return false;
    }
  }

  const onSkip = async () => {
    setIsOnSkipLoading(true);
    const similarJobs = similarJobListRef.current.getSimilarJobs();

    // Submit Interaction
    const responseInteraction =  await submitInteraction({
      agency_id: job.agency_id[0],
      job_id: +jobId,
      hot_lead_job_ids: [],
      similar_job_ids: similarJobs,
      channel: CHANNEL_INTERACTION.I_AM_INTERESTED,
      trigger: TRIGGER_INTERACTION.SIMILAR_JOBS,
    });

    setIsOnSkipLoading(false);

    if (responseInteraction) {
      toggle();

      await handleJobApplication();
    }
  }

  const redirectToSearchJobs = () => {
     toggle();
  };

  return (
    <>
        <div
          id="minimumInformationModal"
          className="modal fade modal-wanderly-chat profile-modal in"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="minimumInformationModalModalLabel"
          data-keyboard="false"
          data-backdrop="static"
          style={{ display: 'block' }}
        >
            <div className="modal-dialog" role="document">
              <MobileNav pageTitle={'Job Interested'} backClick={toggle} />
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="glyphicon glyphicon-remove close"
                    onClick={toggle}
                  ></button>
                </div>
                <div className="modal-body" style={{ margin: 0 }}>
                  {isLoading ?
                    <div className={'col-centered align-center'}>
                      <Oval stroke={'#FAC00A'} width={'100px'} height={'100px'}/>
                    </div> :
                    <div className={`${data && data.length > 0 ? css['wrapper'] : ''}`}>
                      {job?.apply_now_redirect_url || !job?.agency_allows_interactions && (
                        <div className="d-flex justify-content-center">
                          <div className={`${css['header-notification']}`}>
                            <Warning className={`${css['icon']}`} />
                            {job?.apply_now_redirect_url && "You would be redirected to Northside Choice + local site, CONTINUE?"}
                            {!job?.agency_allows_interactions && "This agency is not accepting more applications for this job."}

                            {data && data?.length > 0 && !job.agency_allows_interactions && (
                              <span className="mb-4 mt-2">
                              Wanderly has you covered! Here are recommended jobs you can apply for!
                            </span>
                            )}
                          </div>
                        </div>
                      )}

                      {data && data?.length > 0 ?
                        <>
                          <div className={`${css['similar-jobs-list-wrapper']}`} id="minimum-information-modal-content">
                            <SimilarJobList
                              data={data}
                              setDisabledSendButton={setIsDisabled}
                              ref={similarJobListRef}
                            />
                          </div>
                          <div className={`${css['action-buttons']} flex justify-content-center`}>
                            <Button
                              className={`${css['skip-btn']} ${isOnSkipLoading ? css['loading'] : ''} py-2 cta_after_login_profile_form_im_interested`}
                              type="skip"
                              onClick={onSkip}
                              disabled={isOnSubmitLoading}
                            >
                              <span className={'ms-2 vcenter cta_after_login_profile_form_im_interested'}>SKIP FOR NOW</span>
                            </Button>

                            <Button
                              className={`${css['submit-btn']} ${isOnSubmitLoading ? css['loading'] : ''}  ${isDisabled ? css['disabled'] : ''} py-2 cta_after_login_profile_form_im_interested`}
                              type="submit"
                              onClick={onSubmit}
                              disabled={isDisabled}
                            >
                              <span className={'ms-2 vcenter cta_after_login_profile_form_im_interested'}>
                                <FlightIcon style={{ marginTop: '-4' }} /> OK
                              </span>
                            </Button>
                          </div>
                      </> :
                        <NoSimilarJobs onBrowseAllJobs={redirectToSearchJobs}/>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
        </div>
      <div className="modal-backdrop fade in"></div>
    </>
  );
};

export default SimilarJobsModal;
