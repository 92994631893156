// External dependencies
import React from 'react';
import { format } from 'date-fns';
import { ReactComponent as AddIcon } from '../../assets/images/icons/add-file.svg';
import { ReactComponent as FileIcon } from '../../assets/images/icons/file.svg';
import { Oval } from "svg-loaders-react";

// Styles
import './UploadResume.scss';


const UploadResume = ({
  displayTitle = true,
  errorMessage,
  file,
  handleFileChange,
  isLoadingApplication,
  inputFileRef,
  clearData,
  classname = '',
  isOptional = false
}) => (
  <>
    {isLoadingApplication ? (
      <div className="loading-container" style={{height: '160px'}}>
        <div className={'col-centered align-center'}>
          <Oval stroke={'#FAC00A'} width={'100px'} height={'100px'}/>
        </div>
      </div>
    ) : (
      <div className="upload-resume-container">
        <div className="d-flex flex-column">
          <div className="d-flex flex-column pb-3 py-md-0 px-0 w-full">
            <div className="d-flex flex-column">
              <div className="d-flex flex-column pb-3 pb-sm-5">
                <input
                  ref={inputFileRef}
                  accept=".pdf, .jpeg, .jpg, .png, .bmp, .doc, .docx"
                  className="hidden"
                  id="uploadFile"
                  onChange={handleFileChange}
                  type="file"
                />
                {file ? (
                  <div className="file-info-container pt-3">
                    <div className="file-doc-container">
                      <FileIcon className="file-doc" fill="#303030" />
                    </div>
                    <div className="file-name">
                      <span className="add-file-name">{file?.name}</span>
                      <span className="add-file-name-date">
                        {format(file?.lastModified, 'MMM d, yyyy')}
                      </span>
                    </div>
                    <div
                      className="update-file-btn"
                      onClick={() => {
                        inputFileRef.current.click();
                      }}
                    >
                      Update File
                    </div>
                  </div>
                ) : (
                  <>
                    <label
                      className={`vcenter link-button text-start py-2 add-file-btn d-flex ${classname}`}
                      htmlFor="uploadFile"
                    >
                      <AddIcon fill="#06759A" />
                      <span className="ps-1"> ADD RESUME</span>{' '}
                      {!isOptional && <span className='required-asterisk'>*</span>}
                    </label>
                    <span className="add-file-label">
                      Supported file types: .pdf, .jpeg, .jpg, .png, .bmp, .doc, .docx
                    </span>
                  </>
                )}
                {errorMessage && <span className="help-block">{errorMessage}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);
export default UploadResume;
