import React, {useState} from "react";
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";
import {JobsSelector} from "../../redux/slices/jobs";

import '../SearchControlBox/SearchControlBox.scss';
import BreadC from "../BreadC";

const SeoSearchCity = ({ data }) => {
  const [showFullDescription, setShowFullDescription] = useState(false)
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 992px)',
  });
  const { filters: { typeJob, date, is_asap } = {} } = useSelector(JobsSelector);
  const showDescriptionHtml = data?.location?.description;

  const getShortDescription = (desc) => {
    const regex = /<br\s*\/?>/gi;
    const index = desc.search(regex);

    return data?.location?.description.substr(0, index);
  }

  const descriptionHtml = data?.location?.description && !showFullDescription ? getShortDescription(data?.location?.description) : data?.location?.description;

  return (
    <div className={`container-seo-search ${ !data?.location?.name || !data?.location?.image ? 'container-seo-search-no-border' : '' }`}>
        <BreadC 
          containerClass={`hide-mobile ${ !data?.location?.name || !data?.location?.image ? 'no-border' : '' }`}
          clickable={true}
        />
       <div className={`${ !data?.location?.name || !data?.location?.image ? 'container-seo-search--text' : '' }`}>
        <div className="container-seo-search--title">
          <h1>{data?.location?.h1 || 'Search Healthcare Jobs'}</h1>

          {data?.location?.h2 && (<h2>{data?.location?.h2}</h2>)}
        </div>

      {data?.location?.description && (
        <div className={`container-seo-search--desc ${showFullDescription ? 'text-justify' : 'text-center'}`}>
          {data?.location?.description && (<p style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: descriptionHtml }} />)}
          {showFullDescription ? (
            <div className="text-center"> <br/> <button onClick={() => setShowFullDescription(!showFullDescription)}>Read Less</button> </div>
          ) :  <> ... <button onClick={() => setShowFullDescription(!showFullDescription)}>Read More</button> </> }

        </div>
      )}
      </div>
    </div>
  )
}

export default SeoSearchCity;